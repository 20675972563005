<template>
  <div class="NameTitle flex align-items">
    <div>
      <img src="@/assets/images/vehicles/title-1.png" alt="" />
    </div>
    <div class="name">&nbsp;&nbsp;累计车辆型号数&nbsp;&nbsp;&nbsp;</div>
    <!-- <div class="number">{{ length }}</div> -->
    <animate-number
      from="0"
      :to="length"
      :key="length"
      class="number"
    ></animate-number>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    let { query } = this.$route;
    return {
      length: 0,
      query: query,
    };
  },
  mounted() {
    this.chexingyunyingshuliang();
  },

  methods: {
    chexingyunyingshuliang() {
      let params = {
        enCode: "quanguo_vehicleBrandType",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log("chexingyunyingshuliang=========++", res);
          if (!res || !res.length || !res[0]) return;
          this.length = Number(res[0].vehicle_type) || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
.NameTitle {
  width: 100%;
  height: 78px;
  .name {
    color: #fff;
    font-size: $FontSize28;
  }
  .number {
    font-size: $FontSize60;
    @include ffamily-HT;
    color: #ffd15c;
  }
}
</style>