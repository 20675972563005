<template>
  <div class="vehicles vehiclesPage">
    <MyHeader />
    <heatMap v-show="showVehicleControl && heatMapShow" />
    <!--  -->
    <carmap v-if="!showVehicleControl || !heatMapShow" ref="carsmap" />
    <!-- <he /> -->
    <div class="cover cover-home"></div>
    <div class="page-left flex flex-direction">
      <MyTitle title="交通指标" />
      <div class="page-part mt-10">
        <MyTitle title="电池类型占比" type="2" />
        <!-- <part1 /> -->
        <part10 />
      </div>
      <div class="page-part mt-10">
        <MyTitle title="车辆品牌" type="2" />
        <part3 />
      </div>
      <div class="page-part mt-10">
        <!-- <MyTitle title="车辆型号" type="2" />
        <part2 /> -->
        <MyTitle title="车辆型号" type="2" />
        <part9 />
      </div>
    </div>

    <!-- <div class="page-center-top">
      <target />
    </div>
    <div class="page-center-bottom">
      <part7 />
    </div>
    <div class="page-center">
        
    </div> -->

    <div class="page-right flex flex-direction">
      <MyTitle title="双碳指标" />
      <div class="page-part mt-10">
        <MyTitle title="SOC仿真平均能耗" type="2" />
        <part5 />
      </div>
      <div class="page-part mt-10">
        <!-- <MyTitle title="近7日态势" type="2" />
        <part4 /> -->
        <MyTitle title="SOC仿真平均度电里程" type="2" />
        <part8 />
      </div>
      <div class="page-part mt-10">
        <MyTitle title="SOC平均能耗与度电里程" type="2" />
        <part6 />
      </div>
    </div>
    <div class="footer">
      <target />
    </div>
    
    <!-- <HumanRobot /> -->
  </div>
</template>

<script>
import MyTitle from "@/views/components/MyTitle.vue";
// import part1 from "../components/heatmaoPart/Part_1.vue";
// import part2 from "../components/heatmaoPart/Part_2.vue";
import part3 from "../components/heatmaoPart/Part_3.vue";
// import part4 from "../components/heatmaoPart/Part_4.vue";
import part5 from "../components/heatmaoPart/Part_5.vue";
import part6 from "../components/heatmaoPart/Part_6.vue";
// import part7 from "../components/heatmaoPart/Part_7.vue";
import part8 from "../components/heatmaoPart/Part_8.vue";
import part9 from "../components/heatmaoPart/Part_9.vue";
import part10 from "../components/heatmaoPart/Part_10.vue";
import target from "../components/heatmaoPart/target.vue";
// import vehicles3D from '../../components/map/vehicles3D.vue'
import heatMap from "../../components/map/heatMap.vue";
import carmap from "../../components/map/carmap.vue";
// import he from "../../components/map/h1.vue";
import MyHeader from "@/views/components/MyHeader.vue";
// import HumanRobot from "@/views/components/HumanRobot";
export default {
  components: {
    // part1,
    // part2,
    part3,
    // part4,
    part5,
    part6,
    // part7,
    part8,
    part9,
    part10,
    target,
    MyTitle,
    // vehicles3D,
    MyHeader,
    // HumanRobot,
    heatMap,
    carmap,
    // he
  },
  data() {
    return {
      showVehicleControl: true,
      timer: null,
      heatMapShow: true,
    };
  },
  mounted() {
    var { query } = this.$route;
    console.log(query, "热力图router");
    this.heatMapShow = query.heatMapShow == (false || 'false')  ? false : true;
    console.log(this.heatMapShow,'gasihudsiu')
    if (!this.heatMapShow) {
      console.log('前面')
      this.$nextTick(() => {
        // console.log(query, "hy8sijhodsiohsdiohsdioh");
        console.log('qm面')
        this.$refs.carsmap.init(query.cityName, "", query.value);
      });
    } else {
      console.log('后面')
      this.timer = setTimeout(() => {
        this.showVehicleControl = !this.showVehicleControl;
        if (!this.showVehicleControl) {
          this.$nextTick(() => {
            // this.$refs.carsmap.init(
            //   this.$route.query.cityName,
            //   "",
            //   this.$route.query.value
            // );
            console.log("hm面");
            this.$refs.carsmap.init(this.$route.query?.name || '鄞州区','',this.$route.query?.value || [121.558436,29.831662]);
          });
        }
      }, 15 * 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
$HeaderHeight: 188px;

.cover-home {
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/chonghuan/bg-4.png") no-repeat;
  background-size: 100% 100%;
  z-index: 3;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.vehiclesPage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  .page-left,
  .page-right {
    position: absolute;
    top: $HeaderHeight;
    height: calc(100% - #{$HeaderHeight});
    z-index: 3;
  }
  .page-left,
  .page-right {
    position: absolute;
    width: 739px;
    .page-part {
      width: 100%;
      height: 420px;
    }
  }
  .page-left {
    left: 50px;
  }
  .page-right {
    right: 50px;
  }
  .page-center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: (-50%, -50%);
    .page-center-top {
      position: absolute;
      top: 2%;
      left: 20%;
      transform: (-50%, -50%);
      width: 60%;
      z-index: 3;
    }
    .page-center-bottom {
      width: 560px;
      height: 380px;
      background: #22a1e92b;
      position: absolute;
      bottom: 18.5%;
      right: 20.5%;
      z-index: 3;
    }
  }
  .footer {
    width: 100%;
    position: absolute;
    bottom: 8%;
    left: 27%;
    z-index: 10;
  }
}
</style>