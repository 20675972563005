import BaseApi from './BaseApi';
// import * as constantsConfig from './config';


export default class IndexApi extends BaseApi {
  // ***************************全国、省、市、区begin***************************
  // 18.获取全国站点数据
  async selectCountryStationInfo(info) {
    return await this.get(`screenStationInfo/selectCountryStationInfo`, info);
  }

  // 19.获取省站点数据
  async selectProvinceStationInfo(info) {
    return await this.get(`screenStationInfo/selectProvinceStationInfo`, info);
  }

  // 19.获取市站点数据
  async selectCityStationInfo(info) {
    return await this.get(`screenStationInfo/selectCityStationInfo`, info);
  }

  // 20.获取区站点数据
  async selectDistrictStationInfo(info) {
    return await this.get(`screenStationInfo/selectDistrictStationInfo`, info);
  }
  
  async getCars(info) {
    return await this.get(`https://sass.gclme.com/api/vehiclescheduling/GclVehicleMonitor`, info);
  }

  // ***************************全国、省、市、区end***************************
  
}
export const indexCenter = new IndexApi();