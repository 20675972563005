<template>
  <div class="page">
    <MyEchart
      class="myEchart"
      :visible="cheliangTypeStatisticsShow"
      :id="'CheliangTypeStatistics'"
      :options="cheliangTypeStatisticsOptions" />
  </div>
</template>
    
    <script>
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      // SOC仿真速度统计
      cheliangTypeStatisticsOptions: {},
      cheliangTypeStatisticsShow: false,
    };
  },
  mounted() {
    this.cheliangTypeStatistics();
  },
  methods: {
    // SOC仿真速度统计
    cheliangTypeStatistics() {
      let data = this.dataBase;
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: 'quanguo_shengshi-soc仿真平均能耗', //'quanguo_shengshi-各车辆型号平均能耗',
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      //


      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          // 排序
          let data = res.sort(function (a, b) {
            return a.soc - b.soc;
          });
          // let months = []
          let xAxis = [];
          let seriesData = []
          data.map((item) => {
            xAxis.push(item.soc);
            seriesData.push(
              item.energy_consumption
            );
          });
          let Options = {
            color: ["#62F4CA", "#1A6AFF", "#93E59A", "#46FFF5", "#9256AD"],
            title: {
              text: '度/km',
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: xAxis,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                // name: "平均能耗",
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                  width: 2,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "#53B6BB",
                    },
                    {
                      offset: 1,
                      color: "#53B6BB",
                    },
                  ]),
                },
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#53B6BB",
                    },
                    {
                      offset: 1,
                      color: "rgba(83, 182, 187,0)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: seriesData,
              }
            ],
          };

          this.cheliangTypeStatisticsOptions = Options;
          this.cheliangTypeStatisticsShow = true;

        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../scss/page.scss";
</style>