<template>
  <!-- <div id="container"></div> -->
  <!-- :style="{ opacity: opacityShow }" -->
  <amap :center.sync="center" :zoom="zomm">
    <amap-map-type
      :visible="visible"
      :showTraffic="false"
      :showRoad="false"
      :defaultType="1"
    />
    <div v-for="(item, index) in clusterData" :key="index">
      <div>
        <!-- <amap-control-bar /> -->
        <!-- <amap-tool-bar /> -->
        <!-- <amap-scale :visible="visible" /> -->
        <amap-marker
          :position.sync="item.position"
          :icon="car.icon"
          :offset="car.offset"
          :anchor="anchor"
        >
          <div>
            <!-- 小车暂时详细信息 -->
            <div class="detail-marker" v-if="item.ifDetailCar == false">
              <div>
                <div class="flex centerde">
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa">车牌号</span>
                      <span class="newspa">{{ item.obj.licensePlate }}</span>
                    </div>
                  </span>
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa" style="text-align: center"
                        >状态</span
                      >
                      <span class="newspa">
                        <span
                          class="newspa"
                          v-if="item.obj.engineStatus == '离线'"
                          >离线</span
                        >
                        <span
                          class="newspa"
                          v-if="item.obj.engineStatus == '行驶'"
                          >行驶</span
                        >
                        <span
                          class="newspa"
                          v-if="item.obj.engineStatus == '静止'"
                          >静止</span
                        >
                      </span>
                    </div>
                  </span>
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa">电池SOC:</span>
                      <span class="newspa" style="color: #76de79">
                        <span
                          class="newspa"
                          v-if="item.obj.soc < 50 && item.obj.soc >= 20"
                          style="color: #fddd74"
                        >
                          <span class="newspa" style="font-size: 18px">{{
                            item.obj.soc
                          }}</span
                          >%
                        </span>
                        <span
                          class="newspa"
                          v-if="item.obj.soc >= 0 && item.obj.soc < 20"
                          style="color: #f97e7e"
                        >
                          <span class="newspa" style="font-size: 18px">{{
                            item.obj.soc
                          }}</span
                          >%
                        </span>
                        <span
                          class="newspa"
                          v-if="item.obj.soc >= 50"
                          style="color: #76de79"
                        >
                          <span class="newspa" style="font-size: 18px">{{
                            item.obj.soc
                          }}</span
                          >%
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
                <div class="flex centerde">
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa">车辆类型</span>
                      <span class="newspa">{{ item.obj.vehicleType }}</span>
                    </div>
                  </span>
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa" style="text-align: center"
                        >速度</span
                      >
                      <span class="newspa"> {{ item.obj.speed }}km/h </span>
                    </div>
                  </span>
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa">
                        <span
                          class="newspa"
                          style="color: rgba(0, 0, 0, 0.45); margin-right: 6px"
                          >上传时间:</span
                        >
                        <span class="newspa">{{
                          jnpf.dateFormat(item.obj.ts)
                        }}</span>
                      </span>
                    </div>
                  </span>
                </div>
                <div class="flex centerde">
                  <span class="newspa">当前位置:</span>
                  <span class="newspa">{{ item.obj.address }}</span>
                </div>
                <div class="flex centerde" style="text-align: right">
                  <!-- <el-button @click="showHistory(item)" type="primary" size="mini" >查看历史轨迹</el-button> -->
                </div>
              </div>
            </div>
            <!-- 小车电量 -->
            <div
              @click="clickMyVehical(item)"
              class="detail-marker"
              style="
                width: 122px;
                height: 42px;
                position: absolute;
                top: -40px;
                left: 12px;
              "
            >
              <div>
                <div class="flex centerde">
                  <span class="newspa">
                    <div class="flex centerde">
                      <span class="newspa">
                        <div>
                          <img
                            style="width: 40px"
                            v-if="item.obj.soc >= 50"
                            src="@/assets/images/cella.png"
                            alt=""
                          />
                          <img
                            style="width: 40px"
                            v-if="item.obj.soc < 50 && item.obj.soc >= 20"
                            src="@/assets/images/cellb.png"
                            alt=""
                          />
                          <img
                            style="width: 40px"
                            v-if="item.obj.soc >= 0 && item.obj.soc < 20"
                            src="@/assets/images/cellc.png"
                            alt=""
                          />
                          <img
                            style="width: 40px"
                            v-if="item.obj.soc > 100 && item.obj.soc < 0"
                            src="@/assets/images/celld.png"
                            alt=""
                          />
                        </div>
                      </span>
                      <span class="newspa"></span>
                      <span
                        class="newspa"
                        style="color: #76de79; float: right; margin-bottom: 5px"
                      >
                        <span
                          class="newspa"
                          v-if="item.obj.soc < 50 && item.obj.soc >= 20"
                          style="color: #fddd74"
                          ><span class="newspa" style="font-size: 28px">{{
                            item.obj.soc
                          }}</span
                          >%</span
                        >
                        <span
                          class="newspa"
                          v-if="item.obj.soc >= 0 && item.obj.soc < 20"
                          style="color: #f97e7e"
                          ><span class="newspa" style="font-size: 28px">{{
                            item.obj.soc
                          }}</span
                          >%</span
                        >
                        <span
                          class="newspa"
                          v-if="item.obj.soc >= 50"
                          style="color: #76de79"
                          ><span class="newspa" style="font-size: 28px">{{
                            item.obj.soc
                          }}</span
                          >%</span
                        >
                        <span
                          class="newspa"
                          v-if="item.obj.soc > 100 && item.obj.soc < 0"
                          style="color: #c9c0c0"
                          ><span class="newspa" style="font-size: 28px">{{
                            item.obj.soc
                          }}</span
                          >%</span
                        >
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div @click="clickMyVehical(item)">
              <div v-if="item.vehicleType == '乘用车'">
                <img
                  style="width: 50px; height: 82px; transform: rotate(45deg)"
                  :src="require('@/assets/images/car/car-1.png')"
                  :fit="'fill'"
                />
                <div v-if="item.engineStatus == '行驶'" class="carbase-img"></div>
              </div>
              <div v-else>
                <img
                  style="width: 50px; height: 82px; transform: rotate(45deg)"
                  :src="require('@/assets/images/car/car-2.png')"
                  :fit="'fill'"
                />
                <div v-if="item.engineStatus == '行驶'" class="carbase-img"></div>
              </div>
              <!-- <div v-if="item.obj.soc < 50 && item.obj.soc >= 20">
								<img style="width: 38px; height: 38px" :src="require('@/assets/images/car/car_icon.png')" :fit="'fill'" /> </div>
							<div v-if="item.obj.soc >= 0 && item.obj.soc < 20">
								<img style="width: 38px; height: 38px" :src="require('@/assets/images/car/car_icon.png')" :fit="'fill'" /> </div>
							<div v-if="item.obj.soc >= 50">
								<img style="width: 38px; height: 38px" :src="require('@/assets/images/car/car_icon.png')" :fit="'fill'" /> </div> -->
            </div>
          </div>
        </amap-marker>
      </div>
    </div>
  </amap>
</template>

<script>
import { indexCenter } from "@/api/indexCenter.js";
// import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  components: {},
  data() {
    return {
      plugin: [
        {
          pName: "MapType",
          defaultType: 1, //0代表默认，1代表卫星
          showTraffic: false, // 路网
          showRoad: true, // 路况
        },
      ],
      center: [115, 32],
      clusterData: [],
      anchor: "center",
      visible: false,
      params: {
        longitudeStart: "",
        latitudeStart: "",
        vehicleVin: "",
      },
      listDatas: [],
      car: {
        // path[0].slice(0)
        // pos: path[0].slice(0), // 小车icon的坐标，为空不能设置该key.
        // angle: (Math.atan2(dy, dx) * 180) / Math.PI,
        icon: {
          image: "https://jimnox.gitee.io/amap-vue/assets/car.png",
          imageSize: [26, 50],
        },

        offset: [-13, -25],
      },
      opacityShow: 0,
      zomm: 8.9,
    };
  },
  mounted() {
    let { query } = this.$route;
    console.log(query.heatMapShow, "jisdjiodssjioddiosj");
    if (query.heatMapShow) {
      this.init();
    } else {
      console.log()
    }
  },
  methods: {
    clickMyVehical(item) {
      console.log(item);
      //   localStorage.setItem("newVehicleNum", item.vin);
      if (item.vehicleType === "乘用车") {
        this.$router.push({
          path: "/vehicletype-home",
          query: {
            newVehicleNum: item.vin,
            vehicleType: item.vehicleType,
          },
        });
      } else {
        this.$router.push({
          path: "/vehicletype-home",
          query: {
            newVehicleNum: item.vin,
            vehicleType: item.vehicleType,
          },
        });
      }
    },
    init(city, vehicleVin, value) {
      this.opacityShow = 1;
      let that = this;
      that.center = value;
      this.params.vehicleVin = vehicleVin ? vehicleVin : "";
      console.log(city ? true : false ,'city是true还是false')
      if (city) {
        console.log("暂无数据1");
        console.log(city, "city");
        let cityName = city ? city : "贵阳";
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder({
            city: "", //城市设为北京，默认：“全国”
          });
          console.log(cityName, "到这了");
          geocoder.getLocation(cityName, function (status, result) {
            console.log(status, "来这了", result.geocodes.length);
            if (status === "complete" && result.geocodes.length) {
              var lnglat = result.geocodes[0].location;
              // that.params.longitudeStart = lnglat.lng
              // that.params.latitudeStart = lnglat.lat
              let unmber = 0.1;
              that.params.longitudeStart = lnglat.lng - unmber;
              that.params.longitudeEnd = lnglat.lng + unmber;
              that.params.latitudeStart = lnglat.lat - unmber;
              that.params.latitudeEnd = lnglat.lat + unmber;
              that.center = [lnglat.lng, lnglat.lat];
              console.log(that.params, "thatcenter");
              that.getCars();
              that.zomm = 13;
            } else {
              // log.error('根据地址查询位置失败');
            }
          }); /*  */
        });
      } 

      if(!city) {
        console.log("暂无数据2");
        this.getCars();
        this.zomm = 8.9;
      }
    },
    onSin() {
      // 地图上标注车辆
      // setTimeout(() => {
      if (this.listDatas && this.listDatas.length > 0) {
        for (var i = 0; i < this.listDatas.length; i++) {
          let pos = [
            Number(this.listDatas[i].lnglat[0]),
            Number(this.listDatas[i].lnglat[1]),
          ];
          this.listDatas[i].position = pos;
          this.listDatas[i].obj = this.listDatas[i];
          this.listDatas[i].ifDetailCar = true;
        }
        this.clusterData = this.listDatas.slice(0, 300);
        console.log(this.clusterData);
        this.center = this.clusterData[0].lnglat;
        this.zoom = 9;
        console.log(this.clusterData, "多车数据");
      }
    },
    getCars() {
      console.log(this.params, "this.params");
      console.log('走了这个')
      indexCenter
        .getCars(this.params)
        .then((res) => {
          // this.listDatas = []
          this.clusterData = [];
          console.log(res, "incenter");
          this.listDatas = res[0];
          this.onSin();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {},
  // 销毁定时器
  beforeDestroy: function () {},
};
</script>

<style>
.amap-logo {
  display: none !important;
}
.amap-copyright {
  visibility: hidden !important;
}

.amap-maptypecontrol {
  display: none !important;
}
</style>

<style scoped lang="scss">
@import "@/assets/scss/comm.scss";

.carbase-img {
  position: relative;
  top: -60px;
  left: -1px;
  width: 50px;
  height: 50px;
  background: url(~@/assets/images/exposition/diffusionbg1.png) no-repeat;
  background-size: 80% 80%;
  z-index: 100;
  // transform:  scale(3);
  animation: diffuse 2s ease infinite;
}

@keyframes diffuse {
    0% {
        opacity: 1;
        transform:  scale(1);
    }
    100% {
      position: relative;
      top: -50px;
      left: 10px;
      opacity: .8;
      transform:  scale(3);
    }
}

li.bge5 {
  background-color: #e5e5e5;
}

.newspa {
  margin: 0 2px;
  @include ffamily-DS;
}
</style>

<style  scoped>
.baseInfo-body {
  position: absolute;
  /* bottom: 327px;
  left:262px; */
  /* bottom: 10px;
  left: 253px; */
  width: 100%;
  bottom: 0px;
  /* left: 310px; */
  z-index: 999;
}
/* .baseInfo-body2{
  position: relative;
  top: 423px;
  left:252px;
  z-index:999;
} */
.detail-marker {
  position: absolute;
  top: -244px;
  left: 26px;
  width: 235px;
  border-radius: 5px;
  white-space: nowrap;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  /* text-align: center; */
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  font-family: Roboto;
  /* border: 1px solid rgba(255, 255, 255, 100); */
}
.detail-marker .el-row {
  padding: 4px 2px;
}
.no-data {
  text-align: center;
  font-size: 15px;
  padding: 8px;
}
.marker-using-slot {
  position: absolute;
  top: -28px;
  left: -28px;
  border-radius: 3px;
  white-space: nowrap;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  font-family: Roboto;
  border: 1px solid rgba(255, 255, 255, 100);
}

.baseInfo-wrap {
  position: absolute;
  width: 100%;
}
.car-list li .el-row {
  padding: 2px 0;
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: left;
  font-family: SourceHanSansSC-regular;
}
.hidden-text {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 200px;
}
.car-list li {
  /* border: 1px solid red; */

  border-bottom: 1px solid #ccc;
}
.car-list li:hover {
  background-color: #ececec;
  cursor: pointer;
}
.list-change {
  padding-bottom: 10px;
}
.list-change .active {
  background-color: #fff;
}
.list-change span {
  display: inline-block;
  width: 130px;
  background-color: rgba(243, 246, 249, 100);
  color: rgba(56, 148, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Roboto;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  /* padding:10px;
  color: #3894FF;
  background-color:#fff; */
}
.statusText::before {
  content: "";
  position: relative;
  left: 6px;
  top: -4px;
  border: 1px solid #fddd74;
  background-color: #fddd74;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 12px;
}
.statusText:nth-child(1)::before {
  border: 1px solid #76de79;
  background-color: #76de79;
}
.statusText:nth-child(3)::before {
  border: 1px solid grey;
  background-color: grey;
}

.statusText.offline::before {
  border: 1px solid grey;
  background-color: grey;
}
.statusText.static::before {
  border: 1px solid #fddd74;
  background-color: #fddd74;
}
.statusText.static {
  color: #000000 65%;
}
.statusText.online::before {
  border: 1px solid #76de79;

  background-color: #76de79;
}
.statusText.online {
  color: #000000 65%;
}

.total-wrap {
  display: flex;
  height: 94%;
  border: 1px solid #ccc;
}
.statusDescBar {
  position: relative;
}
.statusDescBar .top {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: space-around;
  left: 355px;
  z-index: 99;
  width: 180px;
  height: 30px;
  padding: 4px 10px;
  line-height: 30px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 2px 2px 10px #909090;
  /* border: 1px solid red; */
  left: 418px;
  line-height: 28px;
  height: 33px;
  top: 24px;
}
.statusDescBar .top2 {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: space-around;
  left: 355px;
  z-index: 99;
  width: 180px;
  height: 30px;
  /* padding: 4px 10px; */
  line-height: 30px;
  /* background-color: #fff; */
  border-radius: 3px;
  box-shadow: 2px 2px 10px #909090;
  /* border: 1px solid red; */
  left: 418px;
  line-height: 28px;
  /* height: 33px; */
  top: 24px;
}
.title-l {
  /* width: 48px;
  height: 20px; */
  font-size: 12px;

  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 20px;
  margin-bottom: 5px;
}
.side {
  width: auto;
  height: 100%;
  overflow-y: auto;
}
.main {
  flex: 1;
}
/* 图标区域 */
.graph-wrap {
  /* position: absolute; */
  width: 420px;
  /* right: 0px; */
  /* z-index: 999; */
  height: 100%;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.car-list {
  margin: 0;
  padding: 0 8px;
  list-style: none;
  width: 250px;
  height: 90%;
  position: relative;
}

.car-list li {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  cursor: default;
}
.car-list li.hover,
.car-list li.active {
  background-color: #e5e5e5;
}

.car-list .name {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.car-list .bar {
  padding: 4px 0 0;
}
.car-list .bar-inner {
  height: 8px;
}
.JNPF-common-layout-center .JNPF-common-layout-main {
  padding-bottom: 0;
}
.car-list-history {
  margin: 0;
  padding: 30px 8px;
  list-style: none;
  /* width: 250px; */
  width: 300px;
  height: 100%;
  position: relative;
}
.car-list-history li {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  cursor: default;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.car-list-history li.hover,
.car-list-history li.active {
  background-color: #e5e5e5;
}
.baseSty {
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #e5e5e5;
  margin-bottom: 20px;
}
.centerde {
  display: flex;
  align-items: center;
  color: #fff;
}
.newspa {
  margin: 0 2px;
}
</style>
