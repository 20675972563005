<template>
  <div class="page">
    <MyEchart
      :visible="cheliangBrandShow"
      :id="'CheliangBrand'"
      :options="cheliangBrandOptions"
    />
  </div>
</template>
  
  <script>
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      // 车辆品牌
      cheliangBrandOptions: {},
      cheliangBrandShow: false,
    };
  },
  mounted() {
    this.cheliangBrand();
  },
  methods: {
    // 车辆品牌
    cheliangBrand(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-车辆品牌",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        if (!res || !res.length) return;
        let data = res.sort(function (a, b) {
          return b.vehicle_num - a.vehicle_num;
        });
        let xAxisData = [];
        let seriesData = [];
        data.map((item) => {
          xAxisData.push(item.vehicle_brand),
            seriesData.push({
              value: parseInt(item.vehicle_num, 10),
              // itemStyle: {
              // 	borderRadius: 5,
              // }
            });
        });
        this.cheliangBrandOptions = {
          title: {
            text: "辆",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              // name: "数量",
              type: "bar",
              barWidth: 20,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3877F1",
                  },
                  {
                    offset: 1,
                    color: "rgba(56, 119, 241,0)",
                  },
                ]),
              },
              data: seriesData,
            },
          ],
        };
        this.cheliangBrandShow = true;
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
</style>