<template>
  <div id="container" style="width: 100%; height: 100%"></div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      clusterData: [],
      geoJson: {
        features: [],
      },
    };
  },
  created() {
    this.selectCountryStationInfo();
  },
  mounted() {
    this.initHeam();
  },
  methods: {
    //获取全国站点数据
    selectCountryStationInfo() {
      let params = {
        enCode: "quanguo_shengshi-rlt", //TiDB-全国地图
        "@ADCODE": "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          console.log("车辆热力图全国", res);
          this.clusterData = res || []
          // this.getGeoJson(100000, "", res);
          this.initHeam(this.clusterData)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGeoJson(adcode, type, stationInfo) {
      // setInterval(() => {
      //   this.opacityShow = 1;
      // }, 2500);
      // // type： province||city
      let that = this;
      /* eslint-disable */
      AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          if (error) {
            console.error(error);
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            console.log(Json, "Json");
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData( stationInfo);
        });
      });
    },
    //获取数据
    async getMapData(stationInfo) {
      console.log(stationInfo, "stationInfocccc");
      let mapData = this.geoJson.features.map((item) => {

        //可以改造数据
        let data = {
          name: item.properties.name,
          //数据value 需要改
          datas: 0,
          position: [],
          cityCode: item.properties.adcode,
          level: item.properties.level, //省 province ，市city ，区district
          value: item.properties.center,
        };
        let currentData = [];
        currentData = stationInfo.filter(
          (item) => (item.adcode || item.district || item.city) == data.cityCode
        );

        if (currentData && currentData.length > 0) {
          data.vehicles = currentData[0].cumulative_number_vehicles_network;
          data.datas = currentData[0].cumulative_number_vehicles_network;
          data.value = item.properties.center;
        }
        return data;
      });
      this.clusterData = mapData;
      this.initHeam(this.clusterData)
    },
    initHeam(clusterData = []) {
      var heatmapData = clusterData.map((item,index) =>{
        return {
          lng: item.longitude || 0,
          lat: item.latitude || 0,
          count: item.datas || 20,
        }
      })

      //  = [
      //   { lng: 116.191031, lat: 39.988585, count: 90 },
      //   { lng: 116.389275, lat: 39.925818, count: 10 },
      //   { lng: 116.287444, lat: 39.810742, count: 20 },
      //   { lng: 116.481707, lat: 39.940089, count: 13 },
      //   { lng: 116.410588, lat: 39.880172, count: 83 },
      //   { lng: 116.394816, lat: 39.91181, count: 45 },
      //   { lng: 116.416002, lat: 39.952917, count: 56 },
      //   { lng: 116.39671, lat: 39.924903, count: 31 },
      //   { lng: 116.180816, lat: 39.957553, count: 28 },
      //   { lng: 116.382035, lat: 39.874114, count: 19 },
      //   { lng: 116.316648, lat: 39.914529, count: 20 },
      //   { lng: 116.395803, lat: 39.908556, count: 21 },
      //   { lng: 116.74553, lat: 39.875916, count: 22 },
      //   { lng: 116.352289, lat: 39.916475, count: 23 },
      //   { lng: 116.441548, lat: 39.878262, count: 24 },
      //   { lng: 116.318947, lat: 39.942735, count: 25 },
      //   { lng: 116.382585, lat: 39.941949, count: 26 },
      //   { lng: 116.42042, lat: 39.884017, count: 27 },
      //   { lng: 116.31744, lat: 39.892561, count: 28 },
      //   { lng: 116.407059, lat: 39.912438, count: 29 },
      //   { lng: 116.412351, lat: 39.888082, count: 30 },
      //   { lng: 116.444341, lat: 39.915891, count: 31 },
      // ];
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [112.418261, 30.921984],
        zoom: 5.6
      });


      var heatmap;
      map.plugin(["AMap.Heatmap"], function () {
        //初始化heatmap对象
        heatmap = new AMap.Heatmap(map, {
          radius: 25, //给定半径
          opacity: [0, 0.8],
            gradient:{
                0.5: 'blue',
                0.65: 'rgb(117,211,248)',
                0.7: 'rgb(0, 255, 0)',
                0.9: '#ffea00',
                1.0: 'red'
            }
        });
        //设置数据集：该数据为北京部分“公园”数据
        heatmap.setDataSet({
          data: heatmapData,
          max: 100,
        });

        var styleName = "amap://styles/" + "darkblue";
        map.setMapStyle(styleName);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>