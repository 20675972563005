<template>
    <div class="page">
      <MyEchart
        :visible="powerExchangeNumberShow"
        :id="'PowerExchangeNumber'"
        :options="powerExchangeNumberOptions"
      />
    </div>
  </template>
    
    <script>
import MyEchart from '@/views/components/charts/echarts'
  import * as echarts from "echarts";
  import { dataInterface } from "@/api/dataInterfaceApi";
  export default {
    components: {
		MyEchart,
    },
    data() {
      let { query } = this.$route;
      return {
        powerExchangeNumberShow: false,
        powerExchangeNumberOptions: {},
        qeury: query,
      };
    },
    mounted() {
      this.chexingyunyingshuliang();
    },
    methods: {
      chexingyunyingshuliang(data = {}) {
			let params = {}
			let query = this.$route.query
			params = {
				'enCode': 'quanguo_shengshi-车辆车型',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) { return b.vehicle_num - a.vehicle_num})
				let xAxisData = []
				let seriesData = []
				data.map( item => {
					xAxisData.push(item.vehicle_model),
					seriesData.push({
						value: parseInt(item.vehicle_num, 10),
						// itemStyle: {
						// 	borderRadius: 5,
						// }
					})
				})
				this.powerExchangeNumberOptions = {
					title: {
						text: '辆',
						top: '3%',
						left: '2%',
						textStyle: {
							color: '#b3b3c9'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					// grid: {
					// 	left: '3%',
					// 	right: '4%',
					// 	bottom: '3%',
					// 	containLabel: true
					// },
					xAxis: [{
						type: 'category',
						data: xAxisData
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '',
						type: 'bar',
						barWidth: 20,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#60F5FF' },
								{ offset: 1, color: 'rgba(96, 245, 255,0)' }
							])
							// color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							// 	{ offset: 0, color: '#83bff6' },
							// 	{ offset: 0.5, color: '#188df0' },
							// 	{ offset: 1, color: '#188df0' }
							// ])
						},
						// emphasis: {
						// 	itemStyle: {
						// 		color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						// 			{ offset: 0, color: '#2378f7' },
						// 			{ offset: 0.7, color: '#2378f7' },
						// 			{ offset: 1, color: '#83bff6' }
						// 		])
						// 	}
						// },
						data: seriesData
					}]
				}
				this.powerExchangeNumberShow = true
			})
		},
    },
  };
  </script>
    
    <style lang="scss" scoped>
  @import "../scss/page.scss";
  </style>