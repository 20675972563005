<template>
  <div class="flex">
    <!-- <div class="postion" v-for="(item, index) in targetList" :key="index">
      <div style="flex: 1;" class="back">
        <p class="text">{{ item.text }}</p>
        <div class="counts">
          <div v-if="item.counts" class="item_counts">{{ item.counts }}</div>
          <div v-else>&nbsp;&nbsp;</div>
          <div class="item_text" v-if="item.counts">
            <span>&nbsp;&nbsp;{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="flex target"
      v-for="(item, index) in targetList"
      :key="'targetList' + index"
    >
      <div>
        <img class="itemImg" src="@/assets/images/heatmap/round.png" alt="" />
      </div>
      <div class="item flex flex-direction">
        <div class="number">{{ item.counts }}</div>
        <!-- <animate-number
          from="0"
          :to="item.counts"
          :key="item.counts"
          class="number"
        ></animate-number> -->
        <div class="flex">
          <span class="name">{{ item.text }}</span>
          <span class="uuid">（{{ item.unit }}）</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
import vehicleAll from "@/assets/images/comm/vehicleAll.png";
import carbonEmissionsAll from "@/assets/images/comm/carbonEmissionsAll.png";
import mileageAll from "@/assets/images/comm/mileageAll.png";
import electricityAll from "@/assets/images/comm/electricityAll.png";
export default {
  data() {
    return {
      targetList: [
        {
          counts: 0,
          text: "车辆总数",
          icon: vehicleAll,
          width: "90px",
          unit: "辆",
        },
        {
          counts: 0,
          text: "碳减排",
          icon: carbonEmissionsAll,
          width: "90px",
          unit: "kg",
        },
        {
          counts: 0,
          text: "总行驶里程",
          icon: mileageAll,
          height: "70px",
          unit: "km",
        },
        {
          counts: 0,
          text: "总放电量",
          icon: electricityAll,
          height: "70px",
          unit: "kWh",
        },
      ],
      timer: null,
    };
  },
  components: {},
  computed: {},
  props: {},
  watch: {},
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    }, 3000);
  },
  methods: {
    // 初始化
    getData(data) {
      console.log("adfadfadsf", data);
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-ssljzb",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          console.log(res, "实时累计指标");
          this.targetList[0].counts = parseInt(res[0].driver_total, 10);
          this.targetList[1].counts = parseInt(res[0].emissions_sum, 10);
          this.targetList[2].counts = parseInt(res[0].mileage_sum, 10);
          this.targetList[3].counts = parseInt(res[0].discharge_power_sum, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 车辆总数
    // vehicleAll(data = {}) {
    //   let params = {};
    //   let query = this.$route.query;
    //   params = {
    //     enCode: "quanguo_shengshi-ssljzb",
    //     "@VEHICLE_TYPE":
    //       query.vehicle == "1"
    //         ? "乘用车"
    //         : query.vehicle == "2"
    //         ? "商用车"
    //         : "全部",
    //     "@ADCODE":
    //       (data ? data.province || data.city || data.adcode : "") || "000000",
    //   };
    //   dataInterface
    //     .dataFaceApi(params)
    //     .then((res) => {
    //       if (!res) return;
    //       let data = (res && res[0]) || {};
    //       this.targetList[0].counts = parseInt(data.total_vehicle_num, 10);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // // 碳减排量
    // carbonEmissionsAll(data = {}) {
    //   let params = {};
    //   let query = this.$route.query;
    //   params = {
    //     enCode: "quanguo_shengshi-碳减排量",
    //     "@VEHICLE_TYPE":
    //       query.vehicle == "1"
    //         ? "乘用车"
    //         : query.vehicle == "2"
    //         ? "商用车"
    //         : "全部",
    //     "@ADCODE":
    //       (data ? data.province || data.city || data.adcode : "") || "000000",
    //   };
    //   dataInterface
    //     .dataFaceApi(params)
    //     .then((res) => {
    //       if (!res) return;
    //       let data = (res && res[0]) || {};
    //       // this.targetList[1].counts = data.carbon_emissions_cc.slice(0, -4) + 'w'
    //       this.targetList[1].counts = data.carbon_emissions_cc;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // // 总里程
    // mileageAll(data = {}) {
    //   let params = {};
    //   let query = this.$route.query;
    //   params = {
    //     enCode: "quanguo_shengshi-总里程",
    //     "@VEHICLE_TYPE":
    //       query.vehicle == "1"
    //         ? "乘用车"
    //         : query.vehicle == "2"
    //         ? "商用车"
    //         : "全部",
    //     "@ADCODE":
    //       (data ? data.province || data.city || data.adcode : "") || "000000",
    //   };
    //   dataInterface
    //     .dataFaceApi(params)
    //     .then((res) => {
    //       if (!res) return;
    //       let data = (res && res[0]) || {};
    //       this.targetList[2].counts = parseInt(
    //         data.total_consumption_mileage,
    //         10
    //       );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // // 总电量
    // electricityAll(data = {}) {
    //   let params = {};
    //   let query = this.$route.query;
    //   params = {
    //     enCode: "quanguo_shengshi-总电量",
    //     "@VEHICLE_TYPE":
    //       query.vehicle == "1"
    //         ? "乘用车"
    //         : query.vehicle == "2"
    //         ? "商用车"
    //         : "全部",
    //     "@ADCODE":
    //       (data ? data.province || data.city || data.adcode : "") || "000000",
    //   };
    //   dataInterface
    //     .dataFaceApi(params)
    //     .then((res) => {
    //       if (!res) return;
    //       let data = (res && res[0]) || {};
    //       this.targetList[3].counts = parseInt(
    //         data.total_consumption_elect,
    //         10
    //       );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
// .postion:nth-child(1) {
//   background: url("~@/assets/images/index/co1.png") no-repeat;
//   background-size: 100% auto;
// }
// .postion:nth-child(2) {
//   background: url("~@/assets/images/index/co2.png") no-repeat;
//   background-size: 100% auto;
// }
// .postion:nth-child(3) {
//   background: url("~@/assets/images/index/co3.png") no-repeat;
//   background-size: 100% auto;
// }
// .postion:nth-child(4) {
//   background: url("~@/assets/images/index/co4.png") no-repeat;
//   background-size: 100% auto;
// }
// .target {
//   width: 100%;
//   height: 122px;
//   display: flex;
//   margin-top: 20px;
//   // margin-bottom:30px;

//   .postion {
//     position: static;
//     z-index: 10;
//     // margin: 0 20px;
//     // flex: 1;
//     width: 240px;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding-right: 20px;
//     .pos-line {
//       width: 6px;
//       height: 62px;
//     }
//     .pos-logo {
//       width: 32px;
//       height: 32px;
//       margin: 0 30px 0 15px;
//     }
//   }
//   .icon {
//     width: 100px;
//     flex-shrink: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .counts {
//     font-size: 30px;
//     // margin-left: 45px;
//     // margin-top:5px;
//     margin-left: 22px;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     color: #ffc166;
//     .item_text {
//       font-size: 14px;
//       color: #fff;
//     }
//     .item_counts {
//       font-family: "DS-Digital-Bold";
//     }
//   }
//   .text {
//     margin-left: 60px;
//     font-size: 22px;
//     line-height: 18px;
//     margin-top: -28px;
//     // height:85px;
//   }
// }
.target {
  width: 485px;
  height: 227px;
  .itemImg {
    width: 250px;
    height: 250px;
    // opacity: 0.6;
  }
  .item {
    margin-top: 40px;
    margin-left: -120px;
    z-index: 4;
    .number {
      color: $FontColor1;
      font-size: 62px;
      @include ffamily-HT;
    }
    .name,
    .uuid {
      display: block;
      color: #fff;
      font-size: $FontSize32;
    }
  }
}
</style>
