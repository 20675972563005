<template>
  <div class="page flex flex-direction">
    <div class="page-1 flex justify-content-around">
      <div class="page-1-1 flex flex-direction align-items">
        <div class="page-1-1-1">
          <MyEchart
            :visible="batteryTypeShow"
            :id="'BatteryTypetanpaijianfenbu1'"
            :options="batteryTypeOptions"
          />
        </div>
        <div class="name mt-20">磷酸铁锂电池</div>
      </div>
      <div class="page-1-1 flex flex-direction align-items">
        <div class="page-1-1-1">
          <MyEchart
            :visible="batteryTypeShow1"
            :id="'BatteryTypetanpaijianfenbu2'"
            :options="batteryTypeOptions1"
          />
        </div>
        <div class="name mt-20">三元材料电池</div>
      </div>
      <div class="page-1-1 flex flex-direction align-items">
        <div class="page-1-1-1">
          <MyEchart
            :visible="batteryTypeShow2"
            :id="'BatteryTypetanpaijianfenbu3'"
            :options="batteryTypeOptions2"
          />
        </div>
        <div class="name mt-20">其他</div>
      </div>
    </div>
    <div class="flex flex1 justify-content-around mt-50">
      <div class="bottom-1"><part11 /></div>
      <div class="bottom-1"><part12 /></div>
    </div>
    <div
      v-if="batteryTypeShow"
      class="number flex align-items"
      style="
        position: absolute;
        top: 16.2%;
        left: 13.5%;
        width: 20px;
        height: 20px;
      "
    >
      <!-- {{ this.number1 }} -->
      <animate-number
        from="0"
        :to="this.number1"
        :key="this.number1"
        class="number"
      ></animate-number>
      <span class="uuid">%</span>
    </div>
    <div
      v-if="batteryTypeShow1"
      class="number flex align-items"
      style="
        position: absolute;
        top: 16.2%;
        left: 47%;
        width: 20px;
        height: 20px;
      "
    >
      <!-- {{ this.number2 }} -->
      <animate-number
        from="0"
        :to="this.number2"
        :key="this.number2"
        class="number"
      ></animate-number>
      <span class="uuid">%</span>
    </div>
    <div
      v-if="batteryTypeShow2"
      class="number flex align-items"
      style="
        position: absolute;
        top: 16.2%;
        left: 80.5%;
        width: 20px;
        height: 20px;
      "
    >
      <!-- {{ this.number3 }} -->
      <animate-number
        from="0"
        :to="this.number3"
        :key="this.number3"
        class="number"
      ></animate-number>
      <span class="uuid">%</span>
    </div>
  </div>
</template>

<script>
import MyEchart from "@/views/exposition/components/charts/echarts.vue";
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
import part11 from "./Part_11.vue";
import part12 from "./Part_12.vue";
export default {
  components: {
    MyEchart,
    part11,
    part12,
  },
  data() {
    return {
      batteryTypeShow: false,
      batteryTypeOptions: {},
      batteryTypeShow1: false,
      batteryTypeOptions1: {},
      batteryTypeShow2: false,
      batteryTypeOptions2: {},
      province: "32",
      number1: 0,
      number2: 0,
      number3: 0,
      timer: null,
    };
  },
  created() {},
  mounted() {
    this.getData();
    // this.timer = setInterval(() => {
    //   this.getData();
    // }, 3000);
  },
  methods: {
    // // 电池类型对比
    // batteryType(data = {}) {
    //   let params = {};
    //   params = {
    //     enCode: "quanguo_shengshi-电池类型占比",
    //     "@ADCODE":
    //       (data ? data.province || data.city || data.adcode : "") || "000000",
    //   };
    //   let colors = [
    //     "#3174FC",
    //     "#11B5EF",
    //     "#F5EF50",
    //     "#91E679",
    //     "#3174FC",
    //     "#11B5EF",
    //     "#F5EF50",
    //     "#91E679",
    //   ];
    //   dataInterface.dataFaceApi(params).then((res) => {
    //     if (!res || !res.length) return;
    //     let data = res;
    //     let seriesData = [];
    //     console.log(res, "电池类型占比");
    //     data.map((item, i) => {
    //       seriesData.push({
    //         value: parseFloat(item.battery_num || 0) * 100,
    //         name:
    //           item.battery_type +
    //           " " +
    //           parseFloat(item.battery_num || 0) * 100 +
    //           "%",
    //         itemStyle: {
    //           color: colors[i],
    //         },
    //       });
    //     });
    //     this.batteryTypeOptions = {
    //       backgroundColor: "rgba(0, 0, 0, 0.1)",
    //       tooltip: {
    //         trigger: "item",
    //       },
    //       legend: {
    //         top: "50%",
    //         // bottom: 0,
    //         left: "10%",
    //         orient: "vertical",
    //         itemWidth: 10,
    //         itemHeight: 10,
    //         textStyle: {
    //           color: "#fff",
    //         },
    //       },
    //       series: [
    //         {
    //           name: "",
    //           type: "pie",
    //           left: "10%",
    //           radius: ["100%", "20%"],
    //           avoidLabelOverlap: false,
    //           emphasis: {
    //             label: {
    //               show: false,
    //               fontSize: 30,
    //               // fontWeight: "bold",
    //             },
    //           },
    //           data: seriesData,
    //         },
    //       ],
    //     };
    //     this.batteryTypeShow = true;
    //   });
    // },
    getData(data = {}) {
      // this.batteryTypeShow = true;
      // this.batteryTypeShow1 = true;
      // this.batteryTypeShow2 = true;
      let params = {};
      params = {
        enCode: "quanguo_shengshi-电池类型占比",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      this.batteryTypeShow = false;
      this.batteryTypeShow1 = false;
      this.batteryTypeShow2 = false;
      var valeu1,
        valeu2,
        valeu3 = 0;
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "电池类型占比");
        let data = res || [];
        // let seriesData = [];
        console.log(res, "电池类型占比");
        let data1 = data.filter((item) => {
          return item.battery_type == "磷酸铁锂电池";
        });
        let data2 = data.filter((item) => {
          return item.battery_type == "三元材料电池";
        });
        let data3 = data.filter((item) => {
          return item.battery_type == "其他";
        });

        valeu1 = Number(parseFloat(data1[0].battery_num) * 100);
        valeu2 = Number(parseFloat(data2[0].battery_num) * 100);
        valeu3 = Number(parseFloat(data3[0].battery_num) * 100);
        this.number1 = Number(parseFloat(data1[0].battery_num) * 100);
        this.number2 = Number(parseFloat(data2[0].battery_num) * 100);
        this.number3 = Number(parseFloat(data3[0].battery_num) * 100);
        this.batteryTypeOptions = {
          backgroundColor: "rgba(0, 0, 0, 0)",
          // tooltip: {
          //   trigger: 'item'
          // },
          // legend: {
          //   top: '15%',
          //   left: 'center'
          // },
          color: ["#69AFF9", "rgba(209,209,209,.2)"],
          series: [
            {
              name: "",
              type: "pie",
              radius: ["60%", "80%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  // show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: valeu1, name: valeu1 },
                { value: 100 - valeu1, name: "" },
              ],
            },
          ],
        };
        this.batteryTypeOptions1 = {
          backgroundColor: "rgba(0, 0, 0, 0)",
          // tooltip: {
          //   trigger: 'item'
          // },
          // legend: {
          //   top: '15%',
          //   left: 'center'
          // },
          color: ["#43EB98", "rgba(209,209,209,.2)"],
          series: [
            {
              name: "",
              type: "pie",
              radius: ["60%", "80%"],
              avoidLabelOverlap: false,
              // label: {
              //   show: false,
              //   position: 'center'
              // },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: valeu2, name: "" },
                { value: 100 - valeu2, name: "" },
              ],
            },
          ],
        };
        this.batteryTypeOptions2 = {
          backgroundColor: "rgba(0, 0, 0, 0)",
          // tooltip: {
          //   trigger: 'item'
          // },
          // legend: {
          //   top: '15%',
          //   left: 'center'
          // },
          color: ["#72CAC5", "rgba(209,209,209,.2)"],
          series: [
            {
              name: "",
              type: "pie",
              radius: ["60%", "80%"],
              avoidLabelOverlap: false,
              // label: {
              //   show: false,
              //   position: 'center'
              // },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: valeu3, name: "" },
                { value: 100 - valeu3, name: "" },
              ],
            },
          ],
        };
        this.batteryTypeShow = true;
        this.batteryTypeShow1 = true;
        this.batteryTypeShow2 = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
.page-1 {
  width: 100%;
  height: 220px;
  .page-1-1 {
    width: 195px;
    height: 100%;
    // background: red;
    .page-1-1-1 {
      width: 100%;
      height: 190px;
    }
  }
  .bottom-1 {
    width: 100%;
    height: 1100%;
  }
}

.name {
  color: #fff;
  font-size: $FontSize24;
}
.number {
  color: #fff;
  font-size: $FontSize40;
  font-weight: bold;
  @include ffamily-DS;
}
.uuid {
  color: #fff;
  font-size: $FontSize24;
}
</style>
