<template>
  <div class="page">
    <MyEchart
      class="myEchart"
      :visible="SOCSimulationShow"
      :id="'SOCSimulation'"
      :options="SOCSimulationOptions"
    />
  </div>
</template>
    
    <script>
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      // SOC仿真能耗统计
      SOCSimulationOptions: {},
      SOCSimulationShow: false,
    };
  },
  mounted() {
    this.SOCSimulation();
  },
  methods: {
    SOCSimulation(data={}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-soc仿真平均度电里程",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      this.SOCSimulationShow = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          console.log(res, 'soc仿真平均度电里程')
          let data = res.sort(function (a, b) {
            return a.soc - b.soc;
          });
          let xAxis = [];
          let yAxis = [];
          data.map((item) => {
            xAxis.push(item.soc);
            yAxis.push(
              parseFloat(
                item.energy_consumption ||
                  item.avg_change_time ||
                  item.avg_per_kwh_mileage
              )
            );
          });
          this.SOCSimulationOptions = {
            title: {
              text: 'km/度',
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: xAxis,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: '',
                type: "line",
                // stack: 'Total',
                smooth: true,
                showSymbol: false,
                lineStyle: {
                  width: 2,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "#4597F7",
                    },
                    {
                      offset: 1,
                      color: "#4597F7",
                    },
                  ]),
                },
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#4597F7",
                    },
                    {
                      offset: 1,
                      color: "rgba(69, 151, 247,0)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: yAxis,
              },
            ],
          };
          this.SOCSimulationShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../scss/page.scss";
</style>