<template>
  <div class="page">
    <MyEchart
      :visible="mileageAndSOCShow"
      :id="'MileageAndSOC'"
      :options="mileageAndSOCOptions"
    />
  </div>
</template>
  
  <script>
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      // SOC、平均能耗与度电里程
      mileageAndSOCOptions: {},
      mileageAndSOCShow: false,
    };
  },
  mounted() {
    this.mileageAndSOC();
  },
  methods: {
    // SOC、平均能耗与度电里程
    mileageAndSOC(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-soc仿真平均能耗和度电里程的关系",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          let data = res.sort(function (a, b) {
            return a.soc - b.soc;
          });
          data = [
            res.map((item) => {
              return [item.soc, item.mileage_per_kwh, item.energy_consumption];
            }),
          ];
          this.mileageAndSOCOptions = {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            tooltip: {
              position: "top",
              formatter: function (params) {
                params = params[0] || {};
                return (
                  "SOC：" +
                  params.value[0] +
                  "<br>" +
                  "平均能耗：" +
                  params.value[1] +
                  "度/min<br>" +
                  "度电里程：" +
                  params.value[2] +
                  "km/度<br>"
                );
              },
              // trigger: 'axis',
              axisPointer: {
                type: "cross",
              },
            },
            xAxis: {
              splitLine: {
                lineStyle: {
                  type: "dashed",
                },
              },
            },
            yAxis: {
              splitLine: {
                lineStyle: {
                  type: "dashed",
                },
              },
              scale: true,
            },
            series: [
              {
                // name: "平均能耗",
                data: data[0],
                type: "scatter",
                symbolSize: function (data) {
                  return Math.sqrt(data[2]) * 35;
                },
                emphasis: {
                  focus: "series",
                  label: {
                    show: true,
                    formatter: function (param) {
                      return param.data[3];
                    },
                    position: "top",
                  },
                },
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: "#22ADD9",
                  shadowOffsetY: 5,
                  color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                    {
                      offset: 0,
                      color: "#22ADD9",
                    },
                    {
                      offset: 1,
                      color: "#22ADD9",
                    },
                  ]),
                },
              },
            ],
          };
          this.mileageAndSOCShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
</style>